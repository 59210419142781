import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import PageLoadingSpinner from '../layout/PageLoadingSpinner';
import useErrorHandler from '../hooks/useErrorHandler';
import constants from '../config/constants';

import { getPermissions } from './api';
import { useAuth0 } from './Auth0Provider';
import { getAssumedRole, roleNamespace, updateAssumedRole } from './assumedRole';

export interface PermissionsInterface {
  hasAssumedRole: (role: string) => boolean;
  getAssumedRole: () => string;
  permissions: string | string[];
  hasPermissions: (permissions: string | string[] | null) => boolean;
  loadingPermissions: boolean;
  changeAssumedRole: (role: string) => void;
  hasMultipleRoles: () => boolean;
}

export const PermissionsContext = createContext({} as PermissionsInterface);

export const usePermissions = () => useContext<PermissionsInterface>(PermissionsContext);

export function PermissionsProvider({ children }: { children: JSX.Element }) {
  const { initializeErrorHandler } = useErrorHandler();

  const { isAuthenticated, user } = useAuth0();

  const [availableRoles, setAvailableRoles] = useState<string[]>();
  const [activeRole, setActiveRole] = useState<string>('');

  useEffect(() => {
    initializeErrorHandler();
  }, [initializeErrorHandler]);

  useEffect(() => {
    const updateRole = async () => {
      const role: string = (await getAssumedRole()) || '';
      setActiveRole(role);
    };

    if (user) {
      const userRoles = user[roleNamespace];
      setAvailableRoles(userRoles);
    }
    updateRole();
  }, [user]);

  const [permissions, setPermissions] = useState<string | string[]>([]);
  const [loadingPermissions, setLoadingPermissions] = useState(isAuthenticated);
  const componentMounted = useRef<boolean>();

  const fetchPermissions = useCallback(async () => {
    if (isAuthenticated) {
      setLoadingPermissions(true);
      try {
        const permissionsResponse = await getPermissions();
        if (componentMounted.current) {
          setPermissions(permissionsResponse.data);
          setLoadingPermissions(false);
        }
      } catch (error) {
        if (componentMounted.current) {
          setLoadingPermissions(false);
        }
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const hasPermissions = useCallback(
    (permission) => {
      if (!permission) {
        return true;
      }
      if (permissions === '*') {
        return true;
      }

      if (Array.isArray(permission)) {
        return Array.isArray(permissions) && permissions.some((r) => permission.indexOf(r) >= 0);
      }
      return permissions.indexOf(permission) >= 0;
    },
    [permissions]
  );

  const hasAssumedRole = useCallback(
    (role) => {
      if (!isAuthenticated || !user) {
        return false;
      }
      return activeRole === role;
    },
    [activeRole, isAuthenticated, user]
  );

  const changeAssumedRole = useCallback((role: string) => {
    const newRole = updateAssumedRole(role);
    setActiveRole(newRole || '');
    window.location.reload();
  }, []);

  const hasMultipleRoles = useCallback(() => {
    return (
      !!availableRoles &&
      availableRoles?.indexOf(constants.roles.ROLE_DRIVER) >= 0 &&
      availableRoles?.indexOf(constants.roles.ROLE_FLEET_MANAGER) >= 0
    );
  }, [availableRoles]);

  const value = useMemo(
    () => ({
      permissions,
      hasPermissions,
      hasAssumedRole,
      loadingPermissions,
      changeAssumedRole,
      getAssumedRole: () => activeRole,
      hasMultipleRoles,
    }),
    [permissions, hasPermissions, hasAssumedRole, loadingPermissions, changeAssumedRole, hasMultipleRoles, activeRole]
  );

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  if (loadingPermissions) {
    return <PageLoadingSpinner />;
  }

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
}
